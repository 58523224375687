import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import Affilate from "../components/Cards/Affilate"
import companies from "../data/bolag"

export default function Recensioner({ data }) {
  return (
    <>
      <Page title={"Recensioner för alternativen på marknaden"}>
        <div className="">
          <div className="container px-5 py-6 mx-auto black ">
            <br />
            <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Recensioner</h1>
            <div>
              <Affilate image={data.fortum} link={"/recensioner/fortum/"} companyUrl={"https://www.fortum.se"} pros={companies.fortum.pros} name={"Fortum"} description="Fortum är ett ledande energibolag som utvecklar och erbjuder sina kunder lösningar inom el, värme, kyla, återvinning och avfallshantering." />
              <Affilate image={data.vattenfall} link={"/recensioner/vattenfall/"} companyUrl={"https://www.vattenfall.se"} pros={companies.vattenfall.pros} name={"Vattenfall"} description="Vattenfall är ett energiföretag som är helägt av svenska staten. Företaget är producent av elektrisk energi, fjärrvärmeleverantör samt är elnätsägare." />
              <Affilate image={data.greenely} link={"/recensioner/greenely/"} companyUrl={"https://www.greenely.se"} pros={companies.greenely.pros} name={"Greenely"} description="Greenely är ett elbolag som använder smart teknik för att hjälpa dig att spara pengar på din elräkning." />

            </div>
          </div>
          <br />

        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    fortum: file(relativePath: { eq: "fortum.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    vattenfall: file(relativePath: { eq: "vattenfall.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    greenely: file(relativePath: { eq: "greenely.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

